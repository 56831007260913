import { useState, useEffect } from 'react'
import { BigNumber } from 'ethers'
import { useConfig, useEthers, useBlockNumber } from '@usedapp/core'
import { useKlubBalance, useTombClaimed, useTombBalance, useKlubsPerTomb, useMaxTokens, useTotalSupply, useClaim, useMintWithKLUB } from '../../hooks'
import { useCanClaimTombs, useRoot, useGetProof } from '../../snapshot'
import { Transactions } from '../notifications/Transactions'
import Stats from './Stats'

global.Buffer = global.Buffer || require('buffer').Buffer

function Main() {

    const { activateBrowserWallet, account } = useEthers()
    const { networks } = useConfig()
    const blockNumber = useBlockNumber()
    const klubBalance = useKlubBalance(account)
    const tombBalance = useTombBalance(account)
    const tombClaimed = useTombClaimed(account)
    const klubsPerTomb = useKlubsPerTomb()
    const maxTokens = useMaxTokens()
    const totalSupply = useTotalSupply()
    const tombsAvailable = totalSupply && maxTokens && totalSupply.lt(maxTokens)
    const canClaimTombs = useCanClaimTombs(account)
    const proof = useGetProof(account, canClaimTombs)
    const root = useRoot()

    const { claim, claimState } = useClaim()
    const [claimErrorMessage, setClaimErrorMessage] = useState()
    const { mintWithKLUB, mintWithKLUBState } = useMintWithKLUB()
    const [mintErrorMessage, setMintErrorMessage] = useState()

    const [errorMessage, setErrorMessage] = useState()
    const [val, setVal] = useState(1)

    const claimBtnClick = async () => {
        setErrorMessage()
        setClaimErrorMessage()
        console.log("claim", canClaimTombs, proof)
        await claim(canClaimTombs, proof)
            .catch((e) => {
                console.log(e)
                if (e) {
                    setErrorMessage(e.message)
                }
            })
    }

    const mintBtnClick = async () => {
        setErrorMessage()
        setMintErrorMessage()
        if (val && val > 0 && val <= 20) {
            try {
                await mintWithKLUB(val)
                    .catch((e) => {
                        console.log(e)
                        if (e) {
                            setErrorMessage(e.message)
                        }
                    })
            } catch (e) {
                setErrorMessage(e.message)
            }
        } else {
            setErrorMessage("Enter number between 1 and 20")
        }
    }
    useEffect(() => {
        if (mintWithKLUBState && mintWithKLUBState.status === 'Exception') {
            setMintErrorMessage(mintWithKLUBState.errorMessage)
            console.log(mintWithKLUBState)
        }
    }, [mintWithKLUBState])

    useEffect(() => {
        if (claimState && claimState.status === 'Exception') {
            setClaimErrorMessage(claimState.errorMessage)
            console.log(claimState)
        }
    }, [claimState])


    useEffect(() => {
        console.log("tombClaimed", tombClaimed)
        console.log("canClaimTombs", canClaimTombs)
        console.log("proof", proof)
        console.log("root", root)
        console.log("klubsPerTomb", klubsPerTomb && klubsPerTomb.toString())
        console.log("tombs purchasable", klubBalance && klubsPerTomb && klubBalance.div(klubsPerTomb).toString())
    }, [tombClaimed, klubsPerTomb, klubBalance, canClaimTombs, proof, root])

    useEffect(() => {
        window.ethereum && window.ethereum.on('chainChanged', (chainId) => {
            if (chainId !== '0x' + networks[0].chainId) {
                window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
            }
        })
    }, [networks])

    const onConnectWallet = async () => {
        setErrorMessage()
        activateBrowserWallet(async (er) => {
            if (er) {
                console.log(er)
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
                activateBrowserWallet()
            }
        }, false)
    }

    return (
        <main className="bg-white sm:max-w-[1024px] mx-auto flex rounded-xl py-6 px-4 my-8 drop-shadow flex-col items-center h-full">
            {!blockNumber && <div className="absolute z-10 w-full h-full backdrop-blur-sm"></div>}
            <form className="w-full">
                {!account &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-yellow-500 rounded hover:bg-yellow-600 active:bg-yellow-500 disabled:bg-yellow-300 disabled:cursor-default" onClick={onConnectWallet}>Connect Wallet</button>}
                {account &&
                    <div>
                        <Stats blockNumber={blockNumber}
                            klubBalance={klubBalance}
                            tombBalance={tombBalance}
                            maxTokens={maxTokens}
                            totalSupply={totalSupply}
                        />

                        <div>
                            <div className="rounded-xl p-10 m-auto overflow-hidden">
                                <img src="/tomb.png" alt="Tomb" />
                            </div>
                        </div>
                        {tombsAvailable && <>
                            <h1 className="my-2 text-2xl font-bold text-center">Get the Tombstone</h1>
                            {/* <h2 className="my-1 text-lg font-bold text-pink-500">{BigNumber.isBigNumber(totalSupply) && totalSupply.toString()}/{BigNumber.isBigNumber(MAX_TOKENS) && MAX_TOKENS.toString()} AT {price && utils.formatEther(price)} EACH</h2>
                        <div className="mb-0 text-base text-blue-400" style={{ fontFamily: "RobotoMedium" }} >Max {BigNumber.isBigNumber(MAX_PER_MINT) && MAX_PER_MINT.toString()} per transaction</div>
                    {account && presaleStarted && !publicSaleStarted && !isSoldOut && <div className="mb-0 text-base text-blue-400" style={{ fontFamily: "RobotoMedium" }}>6 presale mint per wallet</div>} */}
                            <form className="w-full">
                                <div>
                                    <div>
                                        <label htmlFor="quant" className="text-lg block font-medium leading-10 text-center text-gray-500 overflow-ellipsis">Enter quantity:</label>
                                        <input type="number" min="1" max={klubBalance && klubsPerTomb && klubBalance.div(klubsPerTomb).toString()} onFocus={(e) => e.target.select()} value={val} onChange={(e) => { setVal(e.target.value) }} name="quantity" autoFocus className="w-full py-2 mb-4 text-lg font-bold text-center text-blue-600 border outline-none" />
                                        {errorMessage && <h5 className="text-pink-800">{errorMessage}</h5>}
                                    </div>
                                </div>
                                {account && klubsPerTomb && klubBalance && klubBalance.gte(klubsPerTomb.mul(val || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-emerald-500 rounded hover:bg-emerald-600 active:bg-emerald-500 disabled:bg-emerald-300 disabled:cursor-default" onClick={async () => await mintBtnClick().catch((e) => console.log("mint error", e))}>Mint with {klubsPerTomb && klubsPerTomb.mul(val || 0).div(BigNumber.from("10").pow(18)).toString()} $KLUB</button>}
                                {account && klubsPerTomb && klubBalance && klubBalance.lt(klubsPerTomb.mul(val || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>Insuffucient $KLUB</button>}
                                {mintErrorMessage && <h5 className="text-pink-800">{mintErrorMessage}</h5>}
                            </form>
                            <h5 className="mt-4 text-xl text-center text-gray-400">Claim</h5>
                            {canClaimTombs && tombClaimed === false && <button type="button" className="w-full px-5 py-3 mb-8 mt-2 font-semibold text-center text-white bg-cyan-500 rounded hover:bg-cyan-600 active:bg-cyan-500 disabled:bg-cyan-300 disabled:cursor-default" onClick={async () => await claimBtnClick().catch((e) => console.log("claim error", e))}>Claim {canClaimTombs} Tombstones</button>}
                            {canClaimTombs && tombClaimed && <button type="button" className="w-full px-5 py-3 mb-8 mt-4 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>Tombstones Already Claimed</button>}
                            {claimErrorMessage && <h5 className="text-pink-800">{claimErrorMessage}</h5>}
                        </>}
                        {maxTokens && !tombsAvailable && <button type="button" className="w-full px-5 py-3 mb-8 mt-4 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>All Tombstones has been minted!</button>}
                    </div>
                }
            </form>
            <span className="mt-3 text-gray-500" style={{ fontFamily: "RobotoMedium" }}>Current block: {blockNumber}</span>
            <div className='w-full mt-3 text-gray-500' style={{ fontFamily: "RobotoMedium" }}>
                {errorMessage && <h5 className="text-pink-800">{errorMessage}</h5>}
                <div className='w-full text-blue-400'>{<Transactions />}</div>
            </div>
        </main>
    )
}


export default Main;