import { utils } from 'ethers'

import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'

import metadata from './dask-owners-14313622-merged.json'

global.Buffer = global.Buffer || require('buffer').Buffer

const abiEncodeLeaf = (address, tombs) => utils.defaultAbiCoder.encode(["address", "uint"], [address, tombs])

const daskByAddress = metadata.reduce((acc, [address, dasks]) => { acc[address] = dasks; return acc }, {})

const leaves = metadata.filter(([, dasks]) => Math.floor(dasks / 2) > 0).map(([address, dasks]) => keccak256(abiEncodeLeaf(address, Math.floor(dasks / 2))))
const tree = new MerkleTree(leaves, keccak256, { sortPairs: true })

const useCanClaimTombs = (address) => address && daskByAddress && daskByAddress.hasOwnProperty(address) && daskByAddress[address] >= 2 && Math.floor(daskByAddress[address] / 2)

const useRoot = () => tree.getHexRoot()
const useGetProof = (address, tombs) => address && tombs && tree.getHexProof(keccak256(abiEncodeLeaf(address, tombs)))

export { useCanClaimTombs, useRoot, useGetProof }